import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Button, Container, Row, Col, Card } from 'react-bootstrap';

function App() {
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    if (isSubmitting) {
      handleSubmit();
    }
  }, [image]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('checkout_session_id');
    if (sessionId) {
      setSessionId(sessionId);
    }
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setResult(null); // Reset the result when a new image is uploaded
      };
      reader.readAsDataURL(file);
    }
  };

  const handleWebcamCapture = () => {
    if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot();
      setImage(screenshot);
      setUseWebcam(false);
      setResult(null); // Reset the result when a new image is captured
    }
  };

  const formatTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  const handleSubmit = async () => {
    if (useWebcam && !image) {
      handleWebcamCapture();
      setIsSubmitting(true);
      return;
    }

    setLoading(true);
    setResult(null);
    setIsSubmitting(false);

    try {
      // Step 1: Get the direct upload URL
      const uploadUrlResponse = await axios.get(process.env.REACT_APP_UPLOAD_URL);
      const { uploadURL, id } = uploadUrlResponse.data.result;

      // Step 2: Upload the image using the direct upload URL
      const customFilename = `AgeDetector_${formatTimestamp()}`;
      const formData = new FormData();
      formData.append('file', dataURLtoBlob(image), customFilename);
      await axios.post(uploadURL, formData);

      // Step 3: Use the image ID to call the age detection API
      let detectionUrl = `${process.env.REACT_APP_DETECTION_URL}?image_url=${process.env.REACT_APP_IMAGEDELIVERY_URL}/${id}/public`;
      if (sessionId) {
        detectionUrl += `&checkout_session_id=${sessionId}`;
      }
      const result = await axios.get(detectionUrl);
      setResult(result.data);
    } catch (error) {
      setResult({ error: 'Error detecting age' });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setImage(null);
    setResult(null);
    setUseWebcam(false);
    setLoading(false);
    setIsSubmitting(false);
    setSessionId(null);
  };

  const handlePayment = () => {
    window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_URL;
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="text-center p-4 d-flex flex-column justify-content-between">
            <Card.Body className="d-flex flex-column">
              <Card.Title>Age Detector</Card.Title>
              <Button
                variant={sessionId ? "success" : "primary"}
                onClick={handlePayment}
                className="mb-3"
                disabled={!!sessionId}
              >
                {sessionId ? "Young mode enabled 👶" : "Enable young mode 👶"}
              </Button>
              <Card.Text>Start by uploading an image (jpg or png)</Card.Text>
              <Button variant="secondary" onClick={() => { setUseWebcam(!useWebcam); setResult(null); }} className="mb-3">
                {useWebcam ? 'Use File Upload 📄' : 'Use Camera 📸'}
              </Button>
              {useWebcam ? (
                <>
                  <div className="d-flex flex-column align-items-center mb-3">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={320}
                      videoConstraints={{ facingMode: 'user' }}
                    />
                    <Button
                      variant="danger"
                      onClick={handleWebcamCapture}
                      className="mt-3"
                      style={{ borderRadius: '50%' }}
                    >
                      Capture
                    </Button>
                  </div>
                </>
              ) : (
                <input type="file" onChange={handleImageUpload} className="mb-3 form-control" />
              )}
              {image && (
                <div className="d-flex justify-content-center">
                  <img src={image} alt="Selected" className="img-fluid mb-3" style={{ width: '320px', height: 'auto' }} />
                </div>
              )}
            </Card.Body>
            <div>
              <Button variant="primary" onClick={result ? handleReset : handleSubmit} disabled={loading || !image} className="mt-3">
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Detecting Age...
                  </>
                ) : (
                  result ? 'Try Again 😹' : 'Detect Age 👴🏻'
                )}
              </Button>
              {result && (
                <div className="mt-3">
                  <h2>Result</h2>
                  {result.age ? <p>Age: {result.age}</p> : <p>{result.error}</p>}
                </div>
              )}
            </div>
            <footer className="mt-3">
              <p className="small">Made with ❤️ by <a href="https://jamesflores.net" target="_blank">James Flores</a></p>
              <p className="small">Photos are processed server-side and are deleted shortly after.</p>
              <a href="https://www.producthunt.com/posts/age-detector?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-age&#0045;detector" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=459182&theme=light" alt="Age&#0032;Detector - Snap&#0032;and&#0032;see&#0032;your&#0032;age&#0032;instantly&#0032;with&#0032;AI | Product Hunt" width="250" height="54" /></a>
            </footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
